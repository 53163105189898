var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.lists, function(item) {
      return _c("div", { key: item.id, staticClass: "home-list" }, [
        _c("div", { staticClass: "list-theme" }, [_vm._v(_vm._s(item.theme))]),
        _c("div", { staticClass: "list-content" }, [
          _c("div", { staticClass: "adapt" }, [
            _c("div", {
              staticClass: "content-img",
              style: {
                backgroundImage: "url(" + item.thumb + ")",
                backgroundSize: "cover"
              }
            }),
            _c("div", { staticClass: "position" })
          ]),
          _c("div", { staticClass: "content-theme" }, [
            _c("div", { staticClass: "title" }, [_vm._v(_vm._s(item.title))]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(item.description))
            ]),
            _c(
              "div",
              {
                staticClass: "more",
                on: {
                  click: function($event) {
                    return _vm.btnMore(item.id)
                  }
                }
              },
              [_vm._v("更多...")]
            )
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }