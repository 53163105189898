<template>
  <div>
    <Banna :Banna="Banna" />
    <div class="home-view">
      <div class="view-content">
        <div>
          <HomeCarousel />
          <HomeList />
        </div>
      </div>
      <div class="view-advertising">
        <Advertising />
        <Advertising2 />
        <Advertising3 />
      </div>
    </div>
  </div>
</template>
<script>
import HomeCarousel from './components/HomeCarousel';
import HomeList from './components/HomeList';
export default {
  components: {
    HomeCarousel,
    HomeList,
  },
  data() {
    return {
      Banna: {},
    };
  },
  mounted() {
    this.getBanna();
  },
  methods: {
    getBanna() {
      return this.$request(Apis.home.DATUS, { data: { id: '18' } })
        .then(data => {
          this.Banna = data.data.data;
          console.log(this.Banna);
        })
        .finally(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.home-view {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}
.view-content {
  width: 65%;
  padding: 0 10px;
}
.view-advertising {
  width: 25%;
}
</style>
