<template>
  <div>
    <div class="home-list" v-for="item in lists" :key="item.id">
      <div class="list-theme">{{item.theme}}</div>
      <div class="list-content">
        <div class="adapt">
          <div
            class="content-img"
            :style="{ backgroundImage: 'url(' + item.thumb + ')',backgroundSize:'cover' }"
          ></div>
          <div class="position"></div>
        </div>

        <div class="content-theme">
          <div class="title">{{item.title}}</div>
          <div class="content">{{item.description}}</div>
          <div class="more" @click="btnMore(item.id)">更多...</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lists: [],
    };
  },
  mounted() {
    this.honeList();
  },
  methods: {
    honeList() {
      return this.$request(Apis.home.ARTS).then(data => {
        this.lists = data.data.data;
      });
    },
    btnMore(id) {
      this.$router.push('/details/' + id);
    },
  },
};
</script>
<style lang="less" scoped>
.home-list {
  .list-theme {
    margin-top: 30px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
  }
  .list-content {
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .adapt {
      position: relative;
      width: 35%;
      .content-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background: #eee;
      }
      .position {
        padding-bottom: 100%;
      }
    }

    .content-theme {
      width: 60%;
      height: 140px;
      .title {
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 10px;
      }
      .content {
        height: 65%;
        color: #fff;
        font-size: 14px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
        line-height: 1.6;
      }
      .more {
        font-size: 12px;
        color: red;
        text-align: right;
        cursor: pointer;
      }
    }
  }
}
</style>
