<template>
  <div class="homeCarousel" id="home">
    <a-carousel autoplay>
      <template v-for="item in lists">
        <a :key="item.id" :url="item.link_url" _blank>
          <div
            class="homeCarousel-bg"
            :style="{ backgroundImage: 'url('  + item.img_src + ')' ,backgroundSize:'cover'}"
          >
            <p class="homeCarousel-title">{{ item.title }}</p>
          </div>
          <div class="position"></div>
        </a>
      </template>
    </a-carousel>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lists: [],
      uri: '',
    };
  },
  mounted() {
    this.homeCarousel();
  },
  methods: {
    homeCarousel() {
      return this.$request(Apis.home.NOMECAROUSEL).then(data => {
        this.lists = data.data.data;
      });
    },
  },
};
</script>
<style lang="less">
#home .slick-dots {
  bottom: 20px;
  li {
    margin: 0 10px;
    button {
      width: 15px !important;
      height: 15px;
      border-radius: 50% !important;
    }
  }
}
</style>
<style lang="less" scoped>
.homeCarousel {
  .ant-carousel {
    height: 100%;

    .slick-initialized {
      height: 100%;
      .slick-list {
        height: 100%;
      }
      .slick-slide {
        height: 100%;
        a {
          position: relative;
          width: 100%;
          .homeCarousel-bg {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            margin: auto;
            background: #eee;
            background-size: cover;
          }
          .homeCarousel-title {
            text-align: center;
            margin: 0;
            height: 50px;
            line-height: 50px;
            font-size: 17px;
            font-weight: 600;
            color: #fff;
          }
          .position {
            padding-bottom: 60%;
          }
        }
      }
    }
  }
}
</style>
